import React from "react"
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Container,
  Typography,
} from "@material-ui/core"
class ContactForm extends React.Component {
  render() {
    return (
      <Box p={2}>
        <form className="form" action={this.props.action} method="post">
          <Grid
            container
            spacing={1}
            align="center"
            justify="center"
            direction="column"
            xs={10}
          >
            <Grid item>
              <TextField
                fullWidth
                label="Ime i prezime"
                name="fullname"
                id="fullname"
              />
            </Grid>
            <Grid item>
              <TextField fullWidth label="Vaš e-mail" name="email" id="email" />
            </Grid>
            <Grid item>
              <TextField
                rows={3}
                fullWidth
                label="Poruka"
                multiline
                name="message"
                id="message"
              />
            </Grid>
            <Grid item>
              <Button type="submit" color="primary" variant="contained">
                Pošalji
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    )
  }
}
export default ContactForm
